import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-small-effect',
  templateUrl: './icon-small-effect.component.svg',
  styleUrls: ['./icon-small-effect.component.scss'],
})
export class IconSmallEffectComponent {
  @Input() color = '';
}
