import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
interface LumoTip {
  key: string;
  icon: string;
  background: string;
}

@Component({
  selector: 'app-lumo-tips',
  templateUrl: './lumo-tips.component.html',
  styleUrls: ['./lumo-tips.component.scss'],
})
export class LumoTipsComponent {
  tips: LumoTip[] = [
    {
      key: 'CARBON_NEUTRAL_BUILDING_ELECTRICITY',
      icon: 'icon-HouseWithLeaf.svg',
      background: 'CarbonNeutralBuildingElectricity.jpg',
    },
    {
      key: 'CARBON_NEUTRAL_DISTRICT_HEATING',
      icon: 'icon-HouseWithLeaf.svg',
      background: 'CarbonNeutralDistrictHeating.jpg',
    },
    {
      key: 'HEATING_CARBON_COMPENSATION',
      icon: 'icon-HouseWithLeaf.svg',
      background: 'HeatingCarbonCompensation.jpg',
    },
    {
      key: 'SHARED_CAR',
      icon: 'icon-SharedCar.svg',
      background: 'SharedCar.jpg',
    },
    {
      key: 'SHARED_SAUNA',
      icon: 'icon-SharedSauna.svg',
      background: 'SharedSauna.jpg',
    },
    {
      key: 'PARCEL_LOCKER',
      icon: 'icon-ParcelLocker.svg',
      background: 'ParcelLocker.jpg',
    },
    {
      key: 'ELECTRIC_CAR_CHARGING',
      icon: 'icon-ElectricCarCharging.svg',
      background: 'ElectricCarCharging.jpg',
    },
  ];

  currentDetail: LumoTip | undefined;

  constructor(public translate: TranslateService) {}

  showDetail(key: string | undefined) {
    if (key) {
      const item = this.tips.find(i => i.key === key);
      if (item) {
        this.currentDetail = item;
      }
    } else {
      this.currentDetail = undefined;
    }
  }

  onPopupClick(ev: any) {
    // Do nothing if clicking on popup content itself
    if (ev.target.closest('.tip-details')) {
      ev.stopPropagation();
    } else {
      this.showDetail(undefined);
    }
  }
}
