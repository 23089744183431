import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type ChartBarValue = {
  name: string;
  value: number;
  color: string;
  width: string;
  icon: string;
};

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() values: ChartBarValue[];
  @Input() minScale: number;
  @Input() context: string = '';
  @Input() barHeight = '20px';
  @Input() renderIcons = false;
  public currentHover: String;
  public iterations: Array<number>;
  public currentLang: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
  }

  ngOnChanges() {
    this.iterations = Array(this.values.length).fill(null);
    const maxValue = this.calculateMaxValue();

    this.values = this.values.map(value => {
      if (!value.value) {
        return {
          ...value,
          width: '25%',
          color: 'grey',
        };
      }

      const width = this.minScale
        ? Math.floor((value.value / this.minScale) * 100) + '%'
        : Math.floor((value.value / maxValue) * 100) + '%';

      return {
        ...value,
        width,
      };
    });
  }

  toggleTooltip(name?) {
    if (this.currentHover === name) {
      this.currentHover = undefined;
    } else {
      this.currentHover = name;
    }
  }

  private calculateMaxValue() {
    return this.values.map(v => v.value).reduce((prev, curr) => prev + curr, 0);
  }
}
