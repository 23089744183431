import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Result } from '../../interfaces/result';
import { ResultService } from '../result.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateResultService implements OnDestroy {
  private resultStream: BehaviorSubject<Result> = new BehaviorSubject(null);
  private saveResultInvokedStream: BehaviorSubject<Result> =
    new BehaviorSubject(null);
  private subscriptions: Subscription[] = [];

  public constructor(public resultService: ResultService) {
    // Call update API endpoint  when result saving stream emits a new value
    const updateResultSub = this.saveResultInvokedStream
      .pipe(
        filter(result => !!result),
        switchMap(result => {
          return this.resultService.updateResult(result);
        }),
      )
      .subscribe(
        status => status,
        error => console.log(error),
      );

    this.subscriptions.push(updateResultSub);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public emitSaveResult(result: Result) {
    this.resultStream.next(result);
    this.saveResultInvokedStream.next(result);
  }

  public emitResult(result: Result) {
    this.resultStream.next(result);
  }

  public getCurrentResultValue(): Result {
    return this.resultStream.value;
  }

  public getResultStream(): Observable<Result> {
    return this.resultStream.asObservable();
  }

  public getSaveResultStream(): Observable<Result> {
    return this.saveResultInvokedStream.asObservable();
  }
}
