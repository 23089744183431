import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-large-effect',
  templateUrl: './icon-large-effect.component.svg',
  styleUrls: ['./icon-large-effect.component.scss'],
})
export class IconLargeEffectComponent {
  @Input() color = '';
}
