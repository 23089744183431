import { ResidencyLocationAnswerIndex } from 'client/app/models/answer-indices';

export type MunicipalitiesToRegion = {
  municipalityNames: Municipality[];
  answerIndex: ResidencyLocationAnswerIndex;
};

export enum Municipality {
  Espoo = 'Espoo',
  Heinola = 'Heinola',
  Helsinki = 'Helsinki',
  Hollola = 'Hollola',
  Hyvinkää = 'Hyvinkää',
  Hämeenlinna = 'Hämeenlinna',
  Jyväskylä = 'Jyväskylä',
  Järvenpää = 'Järvenpää',
  Kauniainen = 'Kauniainen',
  Kerava = 'Kerava',
  Kirkkonummi = 'Kirkkonummi',
  Kotka = 'Kotka',
  Kuopio = 'Kuopio',
  Lahti = 'Lahti',
  Lappeenranta = 'Lappeenranta',
  Mäntsälä = 'Mäntsälä',
  Naantali = 'Naantali',
  Nurmijärvi = 'Nurmijärvi',
  Oulu = 'Oulu',
  Pirkkala = 'Pirkkala',
  Porvoo = 'Porvoo',
  Riihimäki = 'Riihimäki',
  Rovaniemi = 'Rovaniemi',
  Salo = 'Salo',
  Siilinjärvi = 'Siilinjärvi',
  Tampere = 'Tampere',
  Turku = 'Turku',
  Tuusula = 'Tuusula',
  Vantaa = 'Vantaa',
}

export const municipalityToRegionMap: MunicipalitiesToRegion[] = [
  {
    municipalityNames: [
      Municipality.Espoo,
      Municipality.Heinola,
      Municipality.Helsinki,
      Municipality.Hollola,
      Municipality.Hyvinkää,
      Municipality.Hämeenlinna,
      Municipality.Järvenpää,
      Municipality.Kauniainen,
      Municipality.Kerava,
      Municipality.Kirkkonummi,
      Municipality.Kotka,
      Municipality.Lahti,
      Municipality.Lappeenranta,
      Municipality.Mäntsälä,
      Municipality.Naantali,
      Municipality.Nurmijärvi,
      Municipality.Porvoo,
      Municipality.Riihimäki,
      Municipality.Salo,
      Municipality.Turku,
      Municipality.Tuusula,
      Municipality.Vantaa,
    ],
    answerIndex: ResidencyLocationAnswerIndex.SouthernFinland,
  },
  {
    municipalityNames: [
      Municipality.Jyväskylä,
      Municipality.Kuopio,
      Municipality.Pirkkala,
      Municipality.Siilinjärvi,
      Municipality.Tampere,
    ],
    answerIndex: ResidencyLocationAnswerIndex.CentralFinland,
  },
  {
    municipalityNames: [Municipality.Oulu, Municipality.Rovaniemi],
    answerIndex: ResidencyLocationAnswerIndex.NorthernFinland,
  },
];
