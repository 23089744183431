import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { Result } from '../interfaces/result';
import { TestService } from '../services/test.service';
import { UpdateResultService } from '../services/singleton/update-result.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [
    trigger('fade', [
      state(
        'in',
        style({
          opacity: 1,
        }),
      ),
      state(
        'out',
        style({
          opacity: 0,
        }),
      ),
      transition('out => in', animate('250ms ease-out')),
      transition('in => leave', animate('250ms ease-in')),
    ]),
    trigger('fadeOut', [
      state(
        'in',
        style({
          opacity: 0,
        }),
      ),
      state(
        'out',
        style({
          opacity: 1,
        }),
      ),
      transition('out => in', animate('250ms ease-out')),
      transition('in => leave', animate('250ms ease-in')),
    ]),
  ],
})
export class CategoryComponent implements OnInit, OnDestroy {
  public questionStream: BehaviorSubject<Question> =
    new BehaviorSubject<Question>(null);
  public showEndOfCategoryStream: Observable<boolean> =
    new Observable<boolean>();

  public category: Category;
  public result: Result;
  public showPositive: Boolean = false;
  public showNegative: Boolean = false;
  public animationState: string = 'out';

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public testService: TestService,
    public translate: TranslateService,
    public updateResultService: UpdateResultService,
  ) {}

  public ngOnInit() {
    const currentCategorySub = this.testService.currentCategory.subscribe(
      (value: Category) => {
        this.category = value;
      },
    );

    const currentQuestionSub = this.testService.currentQuestion.subscribe(
      (value: Question) => {
        if (!value) {
          this.chooseEndInfo();
          setTimeout(() => {
            this.animationState = 'in';
          }, 250);
          setTimeout(() => {
            this.focusTitle();
          }, 600);
        } else {
          this.animationState = 'out';
          this.showPositive = false;
          this.showNegative = false;
        }
        this.questionStream.next(value);
      },
    );

    const currentResultSub = this.updateResultService
      .getResultStream()
      .subscribe((value: Result) => {
        this.result = value;
      });

    this.showEndOfCategoryStream = this.questionStream.pipe(
      map(question => !question),
    );

    this.subscriptions.push(
      currentCategorySub,
      currentQuestionSub,
      currentResultSub,
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  chooseEndInfo() {
    if (this.result) {
      this.result.categoryCO2e.map(category => {
        if (category.category._id === this.category._id) {
          if (category.co2e < this.category.averageCo2e) {
            this.showPositive = true;
          } else {
            this.showNegative = true;
          }
        }
      }, this);
    }
  }

  focusTitle() {
    if (isPlatformBrowser(this.platformId)) {
      const focusEl = document.getElementById('category-title');
      if (focusEl) {
        focusEl.focus();
      }
    }
  }

  public saveCategory() {
    this.testService.saveCategory();
    this.animationState = 'out';
  }
}
