<div class="chart" [attr.aria-label]="context">
  <div class="bar-container l-display-flex l-margin-6-t" (clickOutside)="toggleTooltip()">
    <ng-container *ngFor="let it of iterations; index as i; first as isFirst; last as isLast">
      <div
        *ngIf="values[i]"
        class="category-bar bar-animation {{ values[i].color }}"
        [ngClass]="[values[i].name === currentHover ? 'hover' : '', isFirst ? 'first' : '', isLast ? 'last' : '']"
        [style.width]="values[i].width"
        [style.height]="barHeight"
        (click)="toggleTooltip(values[i].name)"
      >
        <div class="icon-container l-display-flex l-justify-center" *ngIf="renderIcons">
          <img class="icon" [src]="'/assets/images/category-icons/' + values[i].icon" alt="category icon" />
        </div>
      </div>
    </ng-container>
  </div>
</div>
