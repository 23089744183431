import {
  Component,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../interfaces/category';
import { Tip } from '../interfaces/tip';

type EnvironmentEffect = {
  fi: string;
  en: string;
  se: string;
};

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
})
export class TipComponent implements OnChanges {
  @Input() tips: Array<Tip>;
  @Input() worstCategory: Category;

  public tags: Array<any> = [];
  public environmentEffects: Array<EnvironmentEffect> = [
    {
      fi: 'pieni',
      en: 'small',
      se: 'liten-inverkan',
    },
    {
      fi: 'keskisuuri',
      en: 'medium',
      se: 'medelstor-inverkan',
    },
    {
      fi: 'suuri',
      en: 'large',
      se: 'stor-inverkan',
    },
  ];

  public tagFilter: string = undefined;
  public environmentEffectFilter: string = undefined;
  public filteringStatus: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,
  ) {}

  ngOnChanges() {
    this.tips
      .filter(tip => tip)
      .map(tip => {
        if (
          this.tags.findIndex(_tag => _tag.fi === tip.tag.fi) === -1 &&
          tip.tag
        ) {
          this.tags.push(tip.tag);
        }
      });
  }

  filterByTag(tag: string) {
    this.tagFilter = tag;

    this.updateFilterStatus();
  }

  filterByEnvironmentEffect(effect?: string) {
    this.environmentEffectFilter = effect;

    this.updateFilterStatus();
  }

  updateFilterStatus() {
    this.filteringStatus = '';
    setTimeout(() => {
      this.filteringStatus = 'done';
    }, 300);
  }
}
