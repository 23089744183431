import {
  BuildingTypeAnswerIndex,
  LivingSpaceAnswerIndex,
  BuildingYearAnswerIndex,
} from 'client/app/models/answer-indices';
import { BuildingType } from 'client/app/models/building-type';

export const apartmentTypeToAnswerMap = [
  {
    buildingType: BuildingType.Block,
    mapsToAnswerIndex: BuildingTypeAnswerIndex.ApartmentBlock,
  },
  {
    apartmentType: BuildingType.RowHouse,
    mapsToAnswerIndex: BuildingTypeAnswerIndex.RowHouseOrDuplex,
  },
  {
    apartmentType: BuildingType.PairHouse,
    mapsToAnswerIndex: BuildingTypeAnswerIndex.RowHouseOrDuplex,
  },
];

export const livingAreaToAnswerMap = [
  {
    minArea: null,
    maxArea: 19,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.LessThanTwentySqm,
  },
  {
    minArea: 20,
    maxArea: 49,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.TwentyToFiftySqm,
  },
  {
    minArea: 50,
    maxArea: 79,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.FiftyToEightySqm,
  },
  {
    minArea: 80,
    maxArea: 119,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.EightyToHundredTwentySqm,
  },
  {
    minArea: 120,
    maxArea: 199,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.HundredTwentyToTwoHundredSqm,
  },
  {
    minArea: 200,
    maxArea: null,
    mapsToAnswerIndex: LivingSpaceAnswerIndex.OverTwoHundredSqm,
  },
];

export const buildingYearToAnswerMap = [
  {
    minYear: null,
    maxYear: 1989,
    mapsToAnswerIndex: BuildingYearAnswerIndex.Pre1990,
  },
  {
    minYear: 1990,
    maxYear: 2010,
    mapsToAnswerIndex: BuildingYearAnswerIndex.Range1990To2010,
  },
  {
    minYear: 2011,
    maxYear: null,
    mapsToAnswerIndex: BuildingYearAnswerIndex.Post2010,
  },
];
