import { Injectable } from '@angular/core';
import { Question } from '../../interfaces/question';
import { TimestampedAnswer } from '../../interfaces/timestamped-answer';
import { prefillRules } from './prefill-rules';
import {
  ApartmentParameter,
  ApartmentParametersService,
} from '../singleton/apartment-parameter.service';

@Injectable()
export class AnswerPrefillService {
  private prefillArguments: ApartmentParameter[] = [];

  constructor(private apartmentParameterService: ApartmentParametersService) {
    this.apartmentParameterService
      .getParametersStream()
      .subscribe(r => (this.prefillArguments = r));
  }

  public isPrefillable(question: Question): boolean {
    return prefillRules.some(r =>
      r.isPrefillable(question, this.prefillArguments),
    );
  }

  public prefillAnswerToQuestion(question: Question): TimestampedAnswer {
    const prefillableAnswer = prefillRules.find(r =>
      r.isPrefillable(question, this.prefillArguments),
    );

    if (!prefillableAnswer) {
      return null;
    }

    const answer = prefillableAnswer.prefillAnswer(
      question.answers,
      this.prefillArguments,
    );

    if (!answer) {
      throw new Error(`Could not prefill answer`);
    }

    return {
      answer,
      isPrefilled: true,
      date: new Date(),
    };
  }
}
