<div class="container">
  <div class="heading-component">
    <div class="heading-container bp0-small-padding">
      <h2 class="tip-heading l-headline is-display is-l l-margin-8-t l-margin-5-b">
        {{ 'LUMO_TIPS_HEADING' | translate }}
      </h2>
      <p class="heading__text l-font-body is-l l-margin-6-b">{{ 'LUMO_TIPS_INTRO' | translate }}</p>
    </div>

    <div class="lumo-tips l-display-flex l-flex-wrap">
      <ng-container *ngFor="let it of tips; index as i">
        <div class="lumo-tip" (click)="showDetail(it.key)">
          <!-- <div class="tip-image" [ngStyle]="{ 'background-image': 'url(/assets/images/' + it.background + ')' }"> -->
          <div class="tip-image">
            <img class="bg" [src]="'/assets/images/' + it.background" />
            <div class="overlay"></div>
            <img class="icon" [src]="'/assets/images/' + it.icon" />
          </div>

          <h4 class="name l-margin-3-t l-margin-3-b l-font-body is-s is-contrast">
            {{ 'LUMO_TIPS.' + it.key + '.TITLE' | translate }}
          </h4>
        </div>
      </ng-container>
    </div>
    <div class="tip-details-wrapper" *ngIf="currentDetail" (click)="onPopupClick($event)">
      <div class="tip-details l-margin-3-l l-margin-3-r">
        <div class="tip-image">
          <img class="bg" [src]="'/assets/images/' + currentDetail.background" />
          <div class="overlay"></div>
          <img class="icon" [src]="'/assets/images/' + currentDetail.icon" />
          <img class="icon-close" [src]="'/assets/images/close_btn_pink.svg'" (click)="showDetail()" />
        </div>
        <div class="content l-padding-6">
          <h3 class="l-headline is-m l-margin-4-b">
            {{ 'LUMO_TIPS.' + currentDetail.key + '.TITLE' | translate }}
          </h3>
          <p class="l-font-body">
            {{ 'LUMO_TIPS.' + currentDetail.key + '.DESCRIPTION' | translate }}
          </p>
          <a
            class="l-margin-4-t l-button is-secondary is-small"
            *ngIf="'LUMO_TIPS.' + currentDetail.key + '.CTA_TARGET' | translate"
            href="{{ 'LUMO_TIPS.' + currentDetail.key + '.CTA_TARGET' | translate }}"
            target="_blank"
            >{{ 'LUMO_TIPS.' + currentDetail.key + '.CTA_TEXT' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
