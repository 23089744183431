import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  ParamMap,
  Router,
} from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { of, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ApartmentParametersService } from './services/singleton/apartment-parameter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public inIframe = false;
  public ariaLock = true;
  private subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private injector: Injector,
    private titleService: Title,
    private translate: TranslateService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private parameterService: ApartmentParametersService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.translate.setDefaultLang('fi');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTitle();
    });

    if (isPlatformServer(this.platformId)) {
      this.translate.use('fi');
      const subdomain = this.injector.get('request').headers.host.split('.')[0];
      if (subdomain === 'elamantapatesti') {
        this.translate.use('fi');
      } else if (subdomain === 'lifestyletest') {
        this.translate.use('en');
      } else if (subdomain === 'livsstilstest') {
        this.translate.use('se');
      }
    }

    // Detect if language is English and change translate.currentLang to affect the whole site
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const pathArgument = event.url.split('/')[1];
        if (pathArgument === 'en') {
          this.translate.use('en');
        } else if (pathArgument === 'se') {
          this.translate.use('se');
        }
      }
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          this.translate.use('fi');
          const subdomain = document.location.hostname.split('.')[0];
          if (subdomain === 'elamantapatesti') {
            this.translate.use('fi');
          } else if (subdomain === 'lifestyletest') {
            this.translate.use('en');
          } else if (subdomain === 'livsstilstest') {
            this.translate.use('se');
          }
        }

        if (this.router.routerState.root.firstChild) {
          this.router.routerState.root.firstChild.data.subscribe(data => {
            if (data.language) {
              this.translate.use(data.language);
            }
          });
        }
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.self !== window.top) {
        this.inIframe = true;
      } else {
        this.inIframe = false;
      }
    }

    // Take query parameters from the stream, remap them as a new observable with each param as a separatedly emitted value
    // forward those to parameter service in sequential order.
    this.subscriptions.add(
      this.activatedRoute.queryParamMap
        .pipe(
          switchMap((m: ParamMap) =>
            of<{ key; value }>().pipe(
              startWith(...m.keys.map(k => ({ key: k, value: m.get(k) }))),
            ),
          ),
          map(p => ({ key: p.key, value: decodeURIComponent(p.value) })),
        )
        .subscribe(p => this.parameterService.setParameter(p)),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  releaseAriaLock(hasConsent: boolean) {
    this.ariaLock = !hasConsent;

    // setTimeout() to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.renderer.selectRootElement('#appTop', true).focus();
    }, 0);
  }

  setTitle() {
    this.translate.get('SHARE.HOME.TITLE').subscribe(translation => {
      this.titleService.setTitle('Sitra ' + translation);
    });
  }
}
