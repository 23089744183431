import { Component } from '@angular/core';
import { EmbedContextService } from '../services/embed-context.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public embedContext: EmbedContextService) {}
}
