import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestNumberingService {
  private currentQuestionNumber = new BehaviorSubject<number>(0);
  private totalQuestionsAmount = new BehaviorSubject<number>(0);

  public get currentQuestionNumber$(): Observable<number> {
    return this.currentQuestionNumber.asObservable();
  }

  public get totalQuestionsAmount$(): Observable<number> {
    return this.totalQuestionsAmount.asObservable();
  }

  public increaseNumbering() {
    this.currentQuestionNumber.next(this.currentQuestionNumber.value + 1);
  }

  public decreaseNumbering(amount: number = 1) {
    this.currentQuestionNumber.next(this.currentQuestionNumber.value - amount);
  }

  public reset(totalQuestions: number) {
    this.currentQuestionNumber.next(0);
    this.totalQuestionsAmount.next(totalQuestions);
  }
}
