import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { Result } from '../interfaces/result';
import { CategoryService } from '../services/category.service';
import { MetaService } from '../services/meta.service';
import { ResultService } from '../services/result.service';
import { UpdateResultService } from '../services/singleton/update-result.service';
import { TestService } from '../services/test.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  providers: [MetaService, TestService],
})
export class TestComponent implements OnInit {
  public categories: Array<Category> = undefined;
  public currentCategory: Category = undefined;
  public currentQuestion: Question = undefined;
  public result: Result;
  public questionCount: number = 0;
  public totalCo2e: number = 0;
  public chartData = [];

  public totalRoundedCo2: Observable<number>;

  private window: Window;

  updateChart() {
    this.chartData = this.result.categoryCO2e.map(c => {
      return {
        name: c.category.title,
        value: Math.round(c.co2e / 100) * 100,
        color: c.category.color,
      };
    });
  }

  constructor(
    public metaService: MetaService,
    public testService: TestService,
    public categoryService: CategoryService,
    public resultService: ResultService,
    public translate: TranslateService,
    public updateResultService: UpdateResultService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.window = this.document.defaultView;
  }

  public ngOnInit() {
    this.metaService.constructMetaTags();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.metaService.constructMetaTags();
    });
    this.testService.currentCategory.subscribe((value: Category) => {
      this.currentCategory = value;
    });
    this.testService.currentQuestion.subscribe((value: Question) => {
      this.currentQuestion = value;
    });

    this.totalRoundedCo2 = this.updateResultService.getResultStream().pipe(
      filter(r => Boolean(r)),
      map(r =>
        r.categoryCO2e.reduce(
          (prev, curr) => prev + Math.round(curr.co2e / 100) * 100,
          0,
        ),
      ),
    );

    this.updateResultService.getResultStream().subscribe((value: Result) => {
      this.result = value;
      if (
        this.result &&
        this.result.categoryCO2e &&
        this.result.categoryCO2e.length > 0
      ) {
        this.updateChart();
      }
    });
    this.categoryService.getAllCategories().subscribe((categories: any) => {
      this.categories = categories;
      this.calculateQuestionCount();
    });
  }

  public goToPreviousQuestion() {
    this.testService.goToPreviousQuestion();
  }

  public isFirstQuestion() {
    return (
      !this.result?.answers || this.result.answers.every(a => a.isPrefilled)
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue =
      'Olet poistumassa testistä, joka poistaa tuloksesi. Oletko varma?';
  }

  private calculateQuestionCount() {
    this.categories.map(category => {
      this.questionCount += category.questions.length;
    });
  }
}
