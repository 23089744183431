import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EmbedContextService {
  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  get context(): 'mylumo' | 'lumofi' {
    const context = this.route.snapshot.queryParams['context'];
    // ActivatedRoute doesn't seem to contain query params on initial load.
    // It gets called multiple times and eventually they are there but not always
    const isMyLumoContext =
      (context && context.toLowerCase() === 'mylumo') ||
      (isPlatformBrowser(this.platformId) &&
        window?.location.href.toLowerCase().indexOf('context=mylumo') >= 0);
    return isMyLumoContext ? 'mylumo' : 'lumofi';
  }
}
