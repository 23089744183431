<div class="category-container">
  <app-question *ngIf="questionStream | async" [@fadeOut]="animationState"> </app-question>
  <div *ngIf="showEndOfCategoryStream | async" class="category-end l-margin-8-t" [@fade]="animationState">
    <div class="category__icon l-display-flex l-justify-center {{ category.color }}">
      <img [src]="'/assets/images/category-icons/' + category.icon" alt="" />
    </div>
    <div class="category__title">
      <h1 class="category-title l-headline is-display l-margin-4-b" tabindex="-1">
        {{ category.title[translate.currentLang] }}
      </h1>
    </div>
    <div class="category__end-info l-margin-4-b">
      <p class="l-font-body is-l" *ngIf="showPositive">
        {{ category.endInfoPositive[translate.currentLang] }}
      </p>
      <p class="l-font-body is-l" *ngIf="showNegative">
        {{ category.endInfoNegative[translate.currentLang] }}
      </p>
      <p class="l-font-body is-l l-margin-2-t">
        {{ category.endInfoCommon[translate.currentLang] }}
      </p>
    </div>
    <div class="button-container" *ngIf="category.index !== 4">
      <button type="button" class="l-button" (click)="saveCategory()">{{ 'CONTINUE' | translate }}</button>
    </div>
    <div class="button-container" *ngIf="category.index === 4">
      <button type="button" class="l-button" (click)="saveCategory()">{{ 'SHOW_RESULTS' | translate }}</button>
    </div>
  </div>
</div>
