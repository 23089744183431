import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-medium-effect',
  templateUrl: './icon-medium-effect.component.svg',
  styleUrls: ['./icon-medium-effect.component.scss'],
})
export class IconMediumEffectComponent {
  @Input() color = '';
}
