import { Answer } from 'client/app/interfaces/answer';
import { Question } from 'client/app/interfaces/question';
import { PrefillableQuestionIndex } from 'client/app/models/question-indices';
import { PrefillRule } from '../../models/prefill-rule';
import { municipalityToRegionMap } from '../municipality-to-region/municiipality-to-region-map';
import {
  ApartmentParameter,
  ParameterType,
} from '../singleton/apartment-parameter.service';
import {
  livingAreaToAnswerMap,
  apartmentTypeToAnswerMap,
  buildingYearToAnswerMap,
} from './answer-maps';

function runAnswerSearch(answers: Answer[], fn: any): Answer {
  const answerData = fn();

  if (!answerData) {
    throw new Error(
      'Answer prefill failed: no answer was matched to arguments',
    );
  }

  const answer = answers.find(a => a.index === answerData.mapsToAnswerIndex);
  return answer;
}

export const prefillRules: PrefillRule[] = [
  {
    title: 'municipality',
    isPrefillable: (
      question: Question,
      prefillArguments: ApartmentParameter[],
    ): boolean => {
      const argument = prefillArguments.find(
        a => a.parameter === ParameterType.Municipality,
      );

      return (
        question.index === PrefillableQuestionIndex.ResidencyLocationQuestion &&
        Boolean(argument?.value)
      );
    },
    prefillAnswer: (
      answers: Answer[],
      prefillArguments: ApartmentParameter[],
    ): Answer =>
      runAnswerSearch(answers, () => {
        const argument = prefillArguments.find(
          a => a.parameter === ParameterType.Municipality,
        );

        const answer = municipalityToRegionMap.find(i =>
          i.municipalityNames.some(n => n === argument.value),
        );

        return {
          mapsToAnswerIndex: answer.answerIndex,
        };
      }),
  },
  {
    title: 'livingArea',
    isPrefillable: (
      question: Question,
      prefillArguments: ApartmentParameter[],
    ): boolean => {
      const argument = prefillArguments.find(
        a => a.parameter === ParameterType.LivingArea,
      );

      return (
        question.index === PrefillableQuestionIndex.LivingAreaQuestion &&
        Boolean(argument?.value)
      );
    },
    prefillAnswer: (
      answers: Answer[],
      prefillArguments: ApartmentParameter[],
    ): Answer =>
      runAnswerSearch(answers, () => {
        const argument = prefillArguments.find(
          a => a.parameter === ParameterType.LivingArea,
        );

        const numericArgument = parseInt(argument.value);

        return livingAreaToAnswerMap.find(
          i =>
            (!i.minArea || numericArgument >= i.minArea) &&
            (!i.maxArea || numericArgument <= i.maxArea),
        );
      }),
  },
  {
    title: 'buildingType',
    isPrefillable: (
      question: Question,
      prefillArguments: ApartmentParameter[],
    ): boolean => {
      const argument = prefillArguments.find(
        a => a.parameter === ParameterType.BuildingType,
      );

      return (
        question.index === PrefillableQuestionIndex.BuildingTypeQuestion &&
        Boolean(argument?.value)
      );
    },
    prefillAnswer: (
      answers: Answer[],
      prefillArguments: ApartmentParameter[],
    ): Answer =>
      runAnswerSearch(answers, () => {
        const argument = prefillArguments.find(
          a => a.parameter === ParameterType.BuildingType,
        );

        const answer = apartmentTypeToAnswerMap.find(
          i => argument.value === i.buildingType,
        );

        return answer;
      }),
  },
  {
    title: 'constructionYear',
    isPrefillable: (
      question: Question,
      prefillArguments: ApartmentParameter[],
    ): boolean => {
      const argument = prefillArguments.find(
        a => a.parameter === ParameterType.ConstructionYear,
      );

      return (
        question.index === PrefillableQuestionIndex.ConstructionYearQuestion &&
        Boolean(argument?.value)
      );
    },
    prefillAnswer: (
      answers: Answer[],
      prefillArguments: ApartmentParameter[],
    ): Answer =>
      runAnswerSearch(answers, () => {
        const argument = prefillArguments.find(
          a => a.parameter === ParameterType.ConstructionYear,
        );

        const numericArgument = parseInt(argument.value);

        return buildingYearToAnswerMap.find(
          i =>
            (!i.minYear || numericArgument >= i.minYear) &&
            (!i.maxYear || numericArgument <= i.maxYear),
        );
      }),
  },
];
