export enum QuestionIndex {
  // Category #1
  ResidencyLocationQuestion = 1,
  BuildingTypeQuestion = 2,
  ConstructionYearQuestion = 3,
  TenantAmountQuestion = 4,
  LivingAreaQuestion = 5,
  ElectricityTypeQuestion = 6,
  RoomTemperatureQuestion = 7,
  WeeklyShowerTimeQuestion = 8,
  SaunaVisitsPerWeekQuestion = 9,
  SaunaTypeQuestion = 10,
  SaunaSharingQuestion = 11,

  // Category #2
  CarUsageQuestion = 12,
  WeeklyCarMileageQuestion = 13,
  CarFuelQuestion = 14,
  CarSharingQuestion = 15,
  WeeklyPublicTransportationQuestion = 16,
  YearlyFlightHoursQuestion = 17,
  FlightCarbonCompensationQuestion = 18,
  YearlyBoatTripsQustion = 19,

  // Category #3
  MealPortionSizeQuestion = 20,
  BeefAmountQuestion = 21,
  SoftCheesePoultryEtcAmountQuestion = 22,
  OtherCheeseProductsAmountQuestion = 23,
  SoftDrinksAmountQuestion = 24,
  EatingOutFrequencyQuestion = 25,
  FoodWasteFrequencyQuestion = 26,

  // Category #4
  ShoppingHabitsQuestion = 27,
  PetSpendingQuestion = 28,
  SummerCabinQuestion = 29,
  SummerCabinSharingQuestion = 30,
}

export enum PrefillableQuestionIndex {
  ResidencyLocationQuestion = 1,
  BuildingTypeQuestion = 2,
  ConstructionYearQuestion = 3,
  LivingAreaQuestion = 5,
}
