import { Category } from './category';
import { TimestampedAnswer } from './timestamped-answer';

export class Result {
  public _id: string;
  public co2e: number;
  public categoryCO2e: { category: Category; co2e: number }[];
  public answers: Array<TimestampedAnswer>;
  public context: string;

  constructor(result: Result) {
    this._id = result._id;
    this.co2e = result.co2e;
    this.categoryCO2e = result.categoryCO2e;
    this.answers = result.answers;
    this.context = result.context;
  }

  public getLatestAnswer(): TimestampedAnswer {
    return this.answers[this.answers.length - 1];
  }
}
