export enum LivingSpaceAnswerIndex {
  LessThanTwentySqm = 107,
  TwentyToFiftySqm = 108,
  FiftyToEightySqm = 109,
  EightyToHundredTwentySqm = 110,
  HundredTwentyToTwoHundredSqm = 111,
  OverTwoHundredSqm = 112,
}

export enum BuildingTypeAnswerIndex {
  ApartmentBlock = 115,
  RowHouseOrDuplex = 116,
}

export enum BuildingYearAnswerIndex {
  Pre1990 = 118,
  Range1990To2010 = 119,
  Post2010 = 120,
}

export enum ResidencyLocationAnswerIndex {
  SouthernFinland = 129,
  CentralFinland = 130,
  NorthernFinland = 131,
}

export enum FamilyMemberAmountAnswerIndex {
  One = 101,
  Two = 102,
  Three = 103,
  Four = 104,
  Five = 105,
  OverFive = 106,
}

export enum ElectricityTypeAnswerIndex {
  EcologicalElectricity = 113,
  OrdinaryElectricity = 114,
}

export enum SaunaVisitsAnswerIndex {
  OncePerWeekOrNone = 138,
  ThreeTimesPerWeek = 139,
  SevenTimesPerWeek = 140,
}

export enum SaunaTypeAnswerIndex {
  PrivateSauna = 141,
  CommonSauna = 142,
}

export enum SaunaSharingAnswerIndex {
  Alone = 143,
  Shared = 144,
}

export enum CarUsageAnswerIndex {
  NoDriving = 201,
}

export enum YearlyFlightAnswerIndex {
  NoTravelByPlane = 221,
}

export enum FlightCarbonCompensationAnswerIndex {
  Compensated = 226,
  NotCompensated = 227,
}

export enum SummerCabinAnswerIndex {
  NoSummerCabin = 405,
}
