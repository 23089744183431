import { Injectable } from '@angular/core';
import { Answer } from '../interfaces/answer';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { Result } from '../interfaces/result';
import {
  CarUsageAnswerIndex,
  SaunaVisitsAnswerIndex,
  SummerCabinAnswerIndex,
  YearlyFlightAnswerIndex,
} from '../models/answer-indices';
import { QuestionIndex } from '../models/question-indices';

@Injectable()
export class QuestionBranchingService {
  public getNextSkippedQuestionAmount(
    question: Question,
    answer: Answer,
  ): number {
    // No sauna => Skip 10, 11
    // No car => Skip 13, 14, 15
    // No flying => Skip 18
    // No cabin => Skip 30
    if (
      question?.index === QuestionIndex.SaunaVisitsPerWeekQuestion &&
      answer.index === SaunaVisitsAnswerIndex.OncePerWeekOrNone
    ) {
      return 2;
    } else if (
      question?.index === QuestionIndex.CarUsageQuestion &&
      answer.index === CarUsageAnswerIndex.NoDriving
    ) {
      return 3;
    } else if (
      question?.index === QuestionIndex.YearlyFlightHoursQuestion &&
      answer.index === YearlyFlightAnswerIndex.NoTravelByPlane
    ) {
      return 1;
    } else if (
      question?.index === QuestionIndex.SummerCabinQuestion &&
      answer.index === SummerCabinAnswerIndex.NoSummerCabin
    ) {
      return 1;
    } else return 0;
  }

  public getPreviousSkippedQuestionAmount(
    currentResult: Result,
    currentQuestion: Question,
    currentCategory: Category,
  ): number {
    // The previous question from this one is the sauna visits question
    // Case 1: category screen
    // Case 2: the first car sharing question
    if (
      (!currentQuestion && currentCategory.index === 1) ||
      currentQuestion?.index == QuestionIndex.CarUsageQuestion
    ) {
      const shouldNotSaunaShowQuestions =
        currentResult.answers[QuestionIndex.SaunaVisitsPerWeekQuestion - 1]
          .answer.index === SaunaVisitsAnswerIndex.OncePerWeekOrNone;

      // The user has answered that he does not use the sauna often, skip questions 10, 11.
      if (shouldNotSaunaShowQuestions) {
        return 2;
      }
    }
    // The previous question from this one is the car sharing question
    else if (
      currentQuestion?.index == QuestionIndex.WeeklyPublicTransportationQuestion
    ) {
      const shouldNotShowCarQuestions =
        currentResult.answers[QuestionIndex.CarUsageQuestion - 1].answer
          .index === CarUsageAnswerIndex.NoDriving;

      // The user has answered that he has no car, skip questions 13, 14, 15.
      if (shouldNotShowCarQuestions) {
        return 3;
      }
    }
    // The previous question from this one is the flying question
    else if (currentQuestion?.index == QuestionIndex.YearlyBoatTripsQustion) {
      const shouldNotFlyingQuestions =
        currentResult.answers[QuestionIndex.YearlyFlightHoursQuestion - 1]
          .answer.index === YearlyFlightAnswerIndex.NoTravelByPlane;

      // The user has answered that he has no car, skip question 18.
      if (shouldNotFlyingQuestions) {
        return 1;
      }
    }

    return 0;
  }
}
