<div class="question-container" [@slide]="animationState">
  <div class="question-progress l-margin-8-t">
    <span class="l-font-body"
      >{{ 'QUESTION' | translate }} {{ currentQuestionNumber$ | async }}/{{ totalQuestionsAmount$ | async }}</span
    >
  </div>
  <fieldset class="question-fieldset">
    <div class="legend">
      <h1 class="question-title l-headline is-display l-margin-6-b l-margin-4-t">
        {{ (question?.title)[translate.currentLang] }}
      </h1>
    </div>
    <div class="answers-container l-display-flex l-flex-direction-column">
      <ng-template *ngIf="isMunicipalityQuestion | async; then municipalityQuestion; else standardQuestion">
      </ng-template>
      <ng-template #municipalityQuestion>
        <ul class="pills l-display-flex l-justify-center l-flex-wrap">
          <li class="l-margin-3-r l-margin-3-t" *ngFor="let municipality of municipalityAnswers">
            <button class="pill l-button" [ngClass]="category.color" (click)="saveMunicipalityAnswer(municipality)">
              <span class="l-font-body is-contrast is-xs">{{ municipality }}</span>
            </button>
          </li>
        </ul>
      </ng-template>
      <ng-template #standardQuestion>
        <ul class="list">
          <li
            *ngFor="let answer of question?.answers | sortByIndex"
            class="answer-button l-display-flex l-justify-center"
            (click)="saveAnswer(answer)"
            [ngClass]="category.color"
            [disabled]="answerClicked | async"
          >
            <span class="l-font-body is-l is-contrast">{{ answer.title[translate.currentLang] }}</span>
          </li>
        </ul>
      </ng-template>
    </div>
  </fieldset>
</div>
