<div class="result-main-container l-display-flex l-flex-direction-column">
  <div class="header-container l-display-flex">
    <div class="banner-header">
      <h1
        *ngIf="bestCategory"
        class="l-headline is-display is-xl l-margin-0"
        [innerHTML]="bestCategory.resultInfo.title[translate.currentLang]"
      ></h1>
    </div>
    <div class="banner-description l-margin-7-l">
      <p class="l-font-body is-l l-margin-4-b" *ngIf="bestCategory">
        {{ bestCategory.resultInfo.positiveDescription[translate.currentLang] }}
      </p>
      <p class="l-font-body is-l l-margin-4-b" *ngIf="worstCategory">
        {{ worstCategory.resultInfo.negativeDescription[translate.currentLang] }}
      </p>
      <p class="l-font-body is-l" *ngIf="bestCategory && bestCategory.index !== 0">
        {{ 'RESULT_COMMON' | translate }}
      </p>
    </div>
  </div>

  <div
    class="result-container l-display-flex l-flex-direction-column l-margin-7-t l-padding-9-l l-padding-9-r l-padding-8-t l-padding-8-b"
    *ngIf="result"
  >
    <div class="result l-padding-8-b" *ngIf="result && resultChart.length > 0">
      <p class="l-font-body is-l">{{ 'YOUR_FOOTPRINT' | translate }}</p>
      <div>
        <span class="l-headline is-display is-s l-margin-2-r">{{ totalRoundedCo2 | thousandSeparator }}</span>
        <span class="l-font-body is-l">kg CO₂e</span>
      </div>
      <div class="chart-container">
        <app-chart
          [values]="resultChart"
          [minScale]="minScale"
          barHeight="36px"
          renderIcons="true"
          *ngIf="resultChart.length > 0"
        ></app-chart>
      </div>
    </div>

    <div class="result l-padding-8-t l-padding-8-b" *ngIf="averageChart.length > 0">
      <p class="l-font-body is-l">{{ 'AVERAGE_FOOTPRINT' | translate }}</p>
      <div>
        <span class="l-headline is-display is-s l-margin-2-r">{{
          averages.average | roundToNearest100 | thousandSeparator
        }}</span>
        <span class="l-font-body is-l">kg CO₂e</span>
      </div>

      <div class="chart-container l-margin-6-b">
        <app-chart
          [values]="averageChart"
          [minScale]="minScale"
          *ngIf="averageChart.length > 0"
          [context]="'AVERAGE_FOOTPRINT' | translate"
          barHeight="36px"
          renderIcons="true"
        ></app-chart>
      </div>
    </div>

    <div class="result l-padding-8-t l-padding-8-b">
      <p class="l-font-body is-l">{{ 'GOAL_FOOTPRINT' | translate }}</p>
      <div>
        <span class="l-headline is-display is-s l-margin-2-r">{{
          goalFootprint | roundToNearest100 | thousandSeparator
        }}</span>
        <span class="l-font-body is-l">kg CO₂e</span>
      </div>

      <div class="chart-container l-margin-6-b">
        <app-chart [values]="goalChart" [minScale]="minScale" barHeight="36px" renderIcons="true"></app-chart>
      </div>

      <a
        class="link-container l-font-body is-contrast"
        [href]="'GOAL_LINK_URL' | translate"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ 'GOAL_LINK_TEXT' | translate }}
      </a>
    </div>
    <div class="legend-container l-display-flex l-margin-8-t l-margin-6-b">
      <ng-container *ngFor="let category of result.categoryCO2e; let i = index">
        <div class="legend">
          <div class="circle {{ category.category.color }} l-margin-3-r"></div>
          <span class="l-font-body">{{ category.category.title[translate.currentLang] }}</span>
        </div>
      </ng-container>
    </div>
    <div class="l-margin-6-b">
      <a
        class="link-container l-font-body is-contrast"
        [href]="'CALCULATIONS_URL' | translate"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ 'CALCULATIONS_TEXT' | translate }}
      </a>
    </div>
    <div class="centered-text-container">
      <button class="l-button is-secondary" routerLink="{{ 'TEST_URL' | translate }}" queryParamsHandling="preserve">
        {{ 'START_OVER' | translate }}
      </button>
    </div>
  </div>

  <app-lumo-tips></app-lumo-tips>

  <app-tip
    *ngIf="tips.length > 0"
    [tips]="tips"
    [worstCategory]="worstCategory"
    [ngClass]="{ hidden: !isBrowser }"
  ></app-tip>

  <div class="l-margin-8-t">
    <a
      class="link-container l-font-body is-contrast"
      href="{{ 'SMART_WAYS_URL' | translate }}"
      target="_blank"
      rel="noopener noreferrer"
      >{{ 'SHOW_ALL_SMART_WAYS' | translate }}</a
    >
  </div>
</div>
