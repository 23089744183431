import { Injectable } from '@angular/core';
import { Answer } from '../interfaces/answer';
import { Result } from '../interfaces/result';
import {
  BuildingTypeAnswerIndex,
  ElectricityTypeAnswerIndex,
  FlightCarbonCompensationAnswerIndex,
  SaunaSharingAnswerIndex,
  SaunaTypeAnswerIndex,
  SaunaVisitsAnswerIndex,
} from '../models/answer-indices';
import { QuestionIndex } from '../models/question-indices';

@Injectable()
export class Co2CalculationService {
  // Residency location related co2 multiplier.
  private getResidencyLocationCo2Multiplier = (result: Result): number => {
    const multiplier =
      result?.answers[QuestionIndex.ResidencyLocationQuestion - 1]?.answer
        ?.multiplier;
    return multiplier ? 1 + multiplier[0] : 1;
  };

  private getAnswerFromEarlier(result: Result, questionIndex: number) {
    const answer = result.answers[questionIndex - 1];

    if (!answer) {
      throw new Error(`Answer with the index ${questionIndex} is missing.`);
    }

    return answer.answer;
  }

  private getMultiplierFromEarlierAnswer(
    result: Result,
    questionIndex: number,
    multiplierIndex: number,
  ) {
    return this.getAnswerFromEarlier(result, questionIndex).multiplier[
      multiplierIndex
    ] as number;
  }

  public calculateAnswerCo2e = (
    result: Result,
    answer: Answer,
    questionIndex: number,
  ) => {
    switch (questionIndex) {
      // 1st category
      case QuestionIndex.LivingAreaQuestion: {
        const residents = this.getAnswerFromEarlier(
          result,
          QuestionIndex.TenantAmountQuestion,
        ).multiplier[0] as number;
        const livingSpace = answer.multiplier[0];
        const livingAreaPerResident = livingSpace / residents;

        // Store the area calculation as the answer's first multiplier. The second multiplier is the undivided area.
        answer.multiplier[0] = livingAreaPerResident;
        return answer;
      }
      case QuestionIndex.RoomTemperatureQuestion: {
        const {
          residencyLocationAnswer,
          roomTemperatureAnswer,
          tenantAmountAnswer,
          livingAreaPerPersonAnswer,
        } = {
          residencyLocationAnswer:
            this.getResidencyLocationCo2Multiplier(result),
          roomTemperatureAnswer: 1 + answer.multiplier[0],
          tenantAmountAnswer: this.getMultiplierFromEarlierAnswer(
            result,
            QuestionIndex.TenantAmountQuestion,
            0,
          ),
          livingAreaPerPersonAnswer: this.getMultiplierFromEarlierAnswer(
            result,
            QuestionIndex.LivingAreaQuestion,
            0,
          ),
        };

        const buildingTypeAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.BuildingTypeQuestion,
        );
        const constructionYearAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.ConstructionYearQuestion,
        );
        const electricityTypeAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.ElectricityTypeQuestion,
        );

        const electricityRelatedCo2 =
          ((electricityTypeAnswer.multiplier[1] +
            (tenantAmountAnswer - 1) * electricityTypeAnswer.multiplier[2]) /
            tenantAmountAnswer) *
          electricityTypeAnswer.multiplier[0];

        const heatingMultiplier =
          buildingTypeAnswer.index === BuildingTypeAnswerIndex.ApartmentBlock
            ? constructionYearAnswer.multiplier[0]
            : constructionYearAnswer.multiplier[1];

        const buildingTypeMultiplier = buildingTypeAnswer.multiplier[0];

        answer.co2e =
          residencyLocationAnswer *
          roomTemperatureAnswer *
          ((buildingTypeMultiplier + heatingMultiplier) *
            livingAreaPerPersonAnswer +
            electricityRelatedCo2);
        return answer;
      }
      case QuestionIndex.WeeklyShowerTimeQuestion: {
        answer.co2e = answer.multiplier[0];
        return answer;
      }
      case QuestionIndex.SaunaVisitsPerWeekQuestion: {
        const electricityTypeAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.ElectricityTypeQuestion,
        );

        if (
          electricityTypeAnswer.index ===
          ElectricityTypeAnswerIndex.EcologicalElectricity
        ) {
          answer.co2e = answer.multiplier[0];
          return answer;
        } else if (
          electricityTypeAnswer.index ===
          ElectricityTypeAnswerIndex.OrdinaryElectricity
        ) {
          answer.co2e = answer.multiplier[1];
          return answer;
        }

        return answer;
      }
      case QuestionIndex.SaunaTypeQuestion: {
        function calculateSaunaEnergyUse(visitsPerWeek: number) {
          const averageStoveEnergyUseOwnSauna = 15 * visitsPerWeek * 52 * 0.155;
          const averageStoveEnergyUseSharedSauna =
            11.3 * visitsPerWeek * 52 * 0.039;
          return (
            averageStoveEnergyUseSharedSauna - averageStoveEnergyUseOwnSauna
          );
        }

        const electricityTypeAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.ElectricityTypeQuestion,
        );
        const visitsAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.SaunaVisitsPerWeekQuestion,
        );

        if (answer.index == SaunaTypeAnswerIndex.PrivateSauna) {
          answer.co2e = 0;
          return answer;
        }

        if (
          electricityTypeAnswer.index ==
            ElectricityTypeAnswerIndex.OrdinaryElectricity &&
          visitsAnswer.index == SaunaVisitsAnswerIndex.ThreeTimesPerWeek
        ) {
          answer.co2e = calculateSaunaEnergyUse(3);
          return answer;
        } else if (
          electricityTypeAnswer.index ==
            ElectricityTypeAnswerIndex.OrdinaryElectricity &&
          visitsAnswer.index == SaunaVisitsAnswerIndex.SevenTimesPerWeek
        ) {
          answer.co2e = calculateSaunaEnergyUse(7);
          return answer;
        } else {
          answer.co2e = 0;
          return answer;
        }
      }
      case QuestionIndex.SaunaSharingQuestion: {
        const visitsAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.SaunaVisitsPerWeekQuestion,
        );
        const typeAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.SaunaTypeQuestion,
        );

        if (answer.index === SaunaSharingAnswerIndex.Alone) {
          answer.co2e = 0;
        } else {
          answer.co2e = -1 * ((visitsAnswer.co2e + typeAnswer.co2e) / 2.5);
        }

        return answer;
      }

      // 2nd category
      case QuestionIndex.WeeklyPublicTransportationQuestion:
      case QuestionIndex.YearlyFlightHoursQuestion:
      case QuestionIndex.YearlyBoatTripsQustion: {
        answer.co2e = answer.multiplier[0];
        return answer;
      }
      case QuestionIndex.CarSharingQuestion: {
        const carMileageMultiplier = this.getMultiplierFromEarlierAnswer(
          result,
          QuestionIndex.WeeklyCarMileageQuestion,
          0,
        );

        const personAmount = answer.multiplier[0];

        const fuelMultiplier = this.getMultiplierFromEarlierAnswer(
          result,
          QuestionIndex.CarFuelQuestion,
          0,
        );

        const fuelInfrastructureMultiplier =
          this.getMultiplierFromEarlierAnswer(
            result,
            QuestionIndex.CarFuelQuestion,
            1,
          );

        const infrastructureMultiplier =
          fuelInfrastructureMultiplier / personAmount;

        answer.co2e =
          carMileageMultiplier * (fuelMultiplier + infrastructureMultiplier);
        return answer;
      }
      case QuestionIndex.FlightCarbonCompensationQuestion: {
        const flightHoursAnswer = this.getAnswerFromEarlier(
          result,
          QuestionIndex.YearlyFlightHoursQuestion,
        );

        if (
          answer.index === FlightCarbonCompensationAnswerIndex.NotCompensated
        ) {
          answer.co2e = 0;
        } else {
          answer.co2e = -1 * (flightHoursAnswer.co2e * 0.5);
        }

        return answer;
      }

      // 3rd category
      case QuestionIndex.MealPortionSizeQuestion:
      case QuestionIndex.EatingOutFrequencyQuestion:
      case QuestionIndex.FoodWasteFrequencyQuestion:
      case QuestionIndex.BeefAmountQuestion:
      case QuestionIndex.SoftCheesePoultryEtcAmountQuestion:
      case QuestionIndex.SoftDrinksAmountQuestion:
      case QuestionIndex.OtherCheeseProductsAmountQuestion: {
        answer.co2e = answer.multiplier[0];
        return answer;
      }

      // 4th category
      case QuestionIndex.ShoppingHabitsQuestion:
      case QuestionIndex.PetSpendingQuestion: {
        answer.co2e = answer.multiplier[0];
        return answer;
      }
      case QuestionIndex.SummerCabinSharingQuestion: {
        const cabinAnswer = this.getMultiplierFromEarlierAnswer(
          result,
          QuestionIndex.SummerCabinQuestion,
          0,
        );

        const sharingAnswer = answer.multiplier[0];

        answer.co2e = cabinAnswer / sharingAnswer;
        return answer;
      }
      default: {
        return answer;
      }
    }
  };
}
