import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../interfaces/category';

@Injectable()
export class CategoryService {
  constructor(public http: HttpClient) {}

  getAllCategories(): Observable<Category[]> {
    return this.http.get<Category[]>('/api/categories');
  }

  getCategoryByName(category): Observable<Category> {
    return this.http.get<Category>('/api/category/name/' + category);
  }
}
