<div class="l-display-flex l-flex-direction-column">
  <h2 class="l-headline is-display is-l l-margin-8-t l-margin-5-b">{{ 'TIPS_HEADING' | translate }}</h2>
  <p class="l-font-body is-l l-margin-5-b">{{ 'TIPS_INTRO' | translate }}</p>
  <div class="tips-count-container">
    <span class="l-headline is-display is-s l-margin-2-r">{{ tips.length }}</span>
    <span class="l-font-body is-l">{{ 'TIPS' | translate }}</span>
  </div>
  <div class="filter-container l-margin-6-t l-margin-6-b">
    <ul class="filter-list l-display-flex">
      <li class="l-margin-3-r" *ngFor="let tag of tags">
        <button
          class="l-button"
          [ngClass]="{ 'is-active': tagFilter === tag[translate.currentLang] }"
          (click)="filterByTag(tag[translate.currentLang])"
        >
          <span class="l-font-body is-contrast is-xs">{{ tag[translate.currentLang] }}</span>
        </button>
      </li>
      <li>
        <button class="l-button" [ngClass]="{ 'is-active': !tagFilter }" (click)="filterByTag(undefined)">
          <span class="l-font-body is-contrast is-s">{{ 'SHOW_ALL' | translate }}</span>
        </button>
      </li>
    </ul>
  </div>

  <div class="filter-container l-margin-6-t l-margin-6-b">
    <p class="l-font-body l-margin-0-t l-margin-4-b">{{ 'ENVIRONMENT_EFFECT' | translate }}</p>
    <ul class="filter-list l-display-flex">
      <li class="l-margin-3-r" *ngFor="let environmentEffect of environmentEffects">
        <button
          class="l-button"
          [ngClass]="{ 'is-active': environmentEffectFilter === environmentEffect[translate.currentLang] }"
          (click)="filterByEnvironmentEffect(environmentEffect[translate.currentLang])"
        >
          <span class="l-font-body is-contrast is-xs">{{ environmentEffect[translate.currentLang] }}</span>
        </button>
      </li>
      <li>
        <button
          class="l-button"
          [ngClass]="{ 'is-active': !environmentEffectFilter }"
          (click)="filterByEnvironmentEffect(undefined)"
        >
          <span class="l-font-body is-contrast is-s">{{ 'SHOW_ALL' | translate }}</span>
        </button>
      </li>
    </ul>
  </div>

  <div class="grid-container" *ngIf="tips">
    <div *ngFor="let tip of tips | filter: tagFilter | filter: environmentEffectFilter | sortByCategory: worstCategory">
      <div class="relative-container l-display-flex l-flex-direction-column">
        <div class="floating-ribbon">
          <span class="l-font-body is-contrast is-s l-padding-3-t l-padding-3-b l-padding-4-l l-padding-4-r">
            {{ tip.tag[translate.currentLang] }}
          </span>
          <div class="ribbon-appendage"></div>
        </div>
        <div class="image-container l-padding-2">
          <a [href]="tip.url[translate.currentLang]" target="_blank">
            <div class="image" [ngStyle]="{ 'background-image': 'url(' + tip.images + ')' }">
              <app-icon-small-effect
                class="floating-carbon-icon"
                color="yellow"
                *ngIf="tip.environmentEffect.en === 'small'"
              ></app-icon-small-effect>
              <app-icon-medium-effect
                class="floating-carbon-icon"
                color="yellow"
                *ngIf="tip.environmentEffect.en === 'medium'"
              ></app-icon-medium-effect>
              <app-icon-large-effect
                class="floating-carbon-icon"
                color="yellow"
                *ngIf="tip.environmentEffect.en === 'large'"
              ></app-icon-large-effect>
            </div>
          </a>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          class="link l-headline is-s l-margin-4-t"
          [href]="tip.url[translate.currentLang]"
          >{{ tip.title[translate.currentLang] }}</a
        >
      </div>
    </div>
  </div>
</div>
