<div class="wizard-main-container l-display-flex l-flex-direction-column">
  <div class="header" *ngIf="result && result.categoryCO2e.length > 0">
    <div class="category-icons l-display-flex" [ngClass]="{ hidden: currentQuestion === undefined }">
      <div class="category-spacer">
        <div class="category-icon-spacer"><div class="category-icon-spacer-line"></div></div>
      </div>
      <ng-container *ngFor="let categoryCO2e of result.categoryCO2e; first as isFirst; last as isLast">
        <div class="category" [ngClass]="[isFirst ? 'is-first' : '', isLast ? 'is-last' : '']">
          <div
            class="category-icon l-display-flex l-justify-center"
            [ngClass]="[
              categoryCO2e.co2e > 0 ||
              categoryCO2e.category._id === currentCategory._id ||
              categoryCO2e.category.index < currentCategory.index
                ? categoryCO2e.category.color
                : 'is-unanswered'
            ]"
          >
            <img [src]="'/assets/images/category-icons/' + categoryCO2e.category.icon" alt="category icon" />
          </div>
          <div class="category-title">
            <span class="l-font-body is-s is-contrast">
              {{ categoryCO2e.category.title[translate.currentLang] }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="content l-padding-8-b">
    <div class="main-column">
      <app-category *ngIf="currentCategory"></app-category>
      <div class="footer l-display-flex l-flex-direction-column l-justify-center">
        <app-chart *ngIf="chartData" [values]="chartData" minValue="10000"></app-chart>
        <div *ngIf="result && result.co2e" class="footprint-total l-margin-4-t">
          <span class="l-font-body">{{ 'YOUR_FOOTPRINT' | translate }}: </span>
          <span class="l-font-body is-contrast">{{ totalRoundedCo2 | async | thousandSeparator }} kg CO₂e</span>
        </div>
        <button *ngIf="!isFirstQuestion()" class="back-button l-button is-text" (click)="goToPreviousQuestion()">
          {{ 'BACK' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
