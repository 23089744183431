import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, share, withLatestFrom } from 'rxjs/operators';
import { Answer } from '../interfaces/answer';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { Result } from '../interfaces/result';
import { QuestionIndex } from '../models/question-indices';
import { CategoryService } from '../services/category.service';
import { ResultService } from '../services/result.service';
import { AnswerPrefillService } from './answer-prefill/answer-prefill.service';
import { Co2CalculationService } from './co2-calculation.service';
import { QuestionBranchingService } from './question-branching.service';
import { QuestionTraversalService } from './question-traversal-service';
import { ApartmentParametersService } from './singleton/apartment-parameter.service';
import { TestNumberingService } from './singleton/test-numbering.service';
import { UpdateResultService } from './singleton/update-result.service';
import { EmbedContextService } from './embed-context.service';

@Injectable()
export class TestService implements OnDestroy {
  public categories: Array<Category>;

  public currentCategory: BehaviorSubject<Category> = new BehaviorSubject(null);
  public currentQuestion: BehaviorSubject<Question> = new BehaviorSubject(null);
  private subscription: Subscription = new Subscription();

  constructor(
    public categoryService: CategoryService,
    public resultService: ResultService,
    public router: Router,
    public translate: TranslateService,
    public questionTraversalService: QuestionTraversalService,
    public updateResultService: UpdateResultService,
    public answerPrefillService: AnswerPrefillService,
    public co2CalculationService: Co2CalculationService,
    public questionBranchingService: QuestionBranchingService,
    public testNumberingService: TestNumberingService,
    public apartmentParameterService: ApartmentParametersService,
    private embedContextService: EmbedContextService,
  ) {
    // Share the API call stream so that we don't end up with multiple calls &
    // weird async problems when the observables emit in varying order.
    const getAllCategories$ = this.categoryService
      .getAllCategories()
      .pipe(share());

    // Reduce & emit all question as a count in value from the categories stream.
    const questionsTotalCount$ = getAllCategories$.pipe(
      map(c => c.reduce((prev, curr) => prev + curr.questions.length, 0)),
    );

    // Emit aparment parameter count
    const apartmentParametersCount$ = this.apartmentParameterService
      .getParametersStream()
      .pipe(map(p => p.length));

    // Combine both of the count streams and calculate a total number of questions.
    // Subscribe to the end result and pass it along to the numbering reset function.
    this.subscription.add(
      questionsTotalCount$
        .pipe(
          withLatestFrom(apartmentParametersCount$),
          map(
            ([questionCount, parametersCount]) =>
              questionCount - parametersCount,
          ),
        )
        .subscribe(c => this.testNumberingService.reset(c)),
    );

    // Get categories and save them
    // Get route params and select right category
    this.subscription.add(
      combineLatest([
        this.resultService.createResult(),
        getAllCategories$,
      ]).subscribe(
        ([result, categories]) => {
          const firstCategoryTitle = categories[0].title;
          this.categories = categories;

          if (result.categoryCO2e.length === 0) {
            result.categoryCO2e.push(
              ...categories.map(category => ({ category: category, co2e: 0 })),
            );
          }

          result.context = this.embedContextService.context;

          this.setCurrentCategory(firstCategoryTitle, result);
          this.setResult(new Result(result));
        },
        error => console.log(error),
      ),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*--------------------------------------------------------------------------
        RESULTS
    --------------------------------------------------------------------------*/

  public setResult = result => {
    this.updateResultService.emitResult(result);
  };

  public getResult() {
    return this.updateResultService.getCurrentResultValue();
  }
  p;
  public goToResultPage() {
    this.getResult().categoryCO2e.map(category => {
      this.getResult().co2e += (category.co2e / 100) * 100;
    });

    this.resultService.saveResult(this.getResult()).subscribe(
      status => status,
      error => error,
    );

    this.translate.get('RESULT_URL').subscribe(translation => {
      this.router.navigate([translation, this.getResult()._id], {
        queryParamsHandling: 'preserve',
      });
    });
  }

  /*--------------------------------------------------------------------------
        CATEGORIES
    --------------------------------------------------------------------------*/

  public setCurrentCategory = (categoryTitle: any, currentResult: Result) => {
    this.categories.map(category => {
      if (category.title === categoryTitle) {
        const firstQuestion = this.getQuestionFromCategory(category, 0);

        if (this.answerPrefillService.isPrefillable(firstQuestion)) {
          const prefilledAnswer =
            this.answerPrefillService.prefillAnswerToQuestion(firstQuestion);

          const { nextQuestion, result } = this.getOrPrefillNextQuestion({
            currentAnswer: prefilledAnswer.answer,
            currentCategory: category,
            currentQuestion: firstQuestion,
            currentResult,
            isPrefilled: prefilledAnswer.isPrefilled,
          });

          this.updateResultService.emitSaveResult(result);
          this.emitNextQuestion(nextQuestion);
        } else {
          this.emitNextQuestion(firstQuestion);
        }

        this.currentCategory.next(category);
      }
    });
  };

  public getCurrentCategory() {
    return this.currentCategory.value;
  }

  public saveCategory() {
    const indexOfCurrentCategory = this.categories.indexOf(
      this.getCurrentCategory(),
    );
    const numberOfCategories = this.categories.length;

    if (indexOfCurrentCategory + 1 < numberOfCategories) {
      this.goToNextCategory(indexOfCurrentCategory, numberOfCategories);
    } else {
      this.goToResultPage();
    }
  }

  private goToNextCategory = (indexOfCurrentCategory, numberOfCategories) => {
    const result = this.getResult();
    this.setCurrentCategory(
      this.categories[indexOfCurrentCategory + 1].title,
      result,
    );
  };

  private goToPreviousCategory(skippedQuestions: number = 0) {
    const previousCategory =
      this.categories[this.categories.indexOf(this.getCurrentCategory()) - 1];
    const questionInPreviousCategory =
      previousCategory.questions[
        previousCategory.questions.length - 1 - skippedQuestions
      ];
    this.currentCategory.next(previousCategory);
    this.emitPreviousQuestion(questionInPreviousCategory, true);
  }

  /*--------------------------------------------------------------------------
        QUESTIONS
    --------------------------------------------------------------------------*/

  public getQuestionFromCategory = (
    category: Category,
    index: number,
  ): Question => {
    const questionAmount = category.questions.length;

    if (index > questionAmount - 1) {
      throw new Error(
        `getQuestionFromCategory: question index ${index} exceeds category question amount ${questionAmount}`,
      );
    }

    return category.questions[index];
  };

  private emitNextQuestion = (question: Question) => {
    // Question is undefined when changing to next category
    if (question) {
      this.testNumberingService.increaseNumbering();
    }

    this.currentQuestion.next(question);
  };

  private emitPreviousQuestion = (
    question: Question,
    decreaseNumbering: boolean,
  ) => {
    if (decreaseNumbering) {
      this.testNumberingService.decreaseNumbering();
    }

    this.currentQuestion.next(question);
  };

  private getCurrentQuestion() {
    return this.currentQuestion.value;
  }

  public saveAnswer(currentAnswer: Answer): void {
    const { nextQuestion, result } = this.getOrPrefillNextQuestion({
      currentAnswer,
      currentCategory: this.getCurrentCategory(),
      currentQuestion: this.getCurrentQuestion(),
      currentResult: this.getResult(),
      isPrefilled: false,
    });

    this.updateResultService.emitSaveResult(result);
    this.emitNextQuestion(nextQuestion);
  }

  private getOrPrefillNextQuestion(args: {
    currentAnswer: Answer;
    currentQuestion: Question;
    currentCategory: Category;
    currentResult: Result;
    isPrefilled: boolean;
  }): { nextQuestion: Question; result: Result } {
    const {
      currentAnswer,
      currentQuestion,
      currentCategory,
      currentResult,
      isPrefilled,
    } = {
      ...args,
    };

    const co2CalculatedAnswer = this.co2CalculationService.calculateAnswerCo2e(
      currentResult,
      currentAnswer,
      currentQuestion.index,
    );

    this.updateResult(co2CalculatedAnswer, currentResult, isPrefilled);

    const skippedQuestions =
      this.questionBranchingService.getNextSkippedQuestionAmount(
        currentQuestion,
        currentAnswer,
      );

    const nextQuestion = this.questionTraversalService.getNextQuestion({
      currentCategory,
      currentQuestion,
      skippedQuestions: skippedQuestions,
    });

    // Answers have to be marked as undefined in order for the answers-array indexing
    // to keep up with the questions.
    for (let i = 0; i < skippedQuestions; ++i) {
      currentResult.answers.push({
        answer: null,
        date: new Date(),
        isPrefilled: null,
      });
      this.testNumberingService.increaseNumbering();
    }

    // Last question reached
    if (!nextQuestion) {
      return {
        nextQuestion: null,
        result: currentResult,
      };
    }

    if (this.answerPrefillService.isPrefillable(nextQuestion)) {
      const prefilledAnswer =
        this.answerPrefillService.prefillAnswerToQuestion(nextQuestion);

      return this.getOrPrefillNextQuestion({
        currentAnswer: prefilledAnswer.answer,
        currentQuestion: nextQuestion,
        currentCategory,
        currentResult,
        isPrefilled: prefilledAnswer.isPrefilled,
      });
    }

    return {
      nextQuestion,
      result: currentResult,
    };
  }

  public goToPreviousQuestion() {
    const currentResult = this.getResult();
    const currentCategory = this.getCurrentCategory();
    const currentQuestion = this.getCurrentQuestion();

    // If first question or second question -> navigate to the landing page
    if (
      currentQuestion &&
      currentQuestion.index === QuestionIndex.ResidencyLocationQuestion
    ) {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
      return;
    }

    this.getPreviousQuestionAndCleanAnswers({
      currentResult,
      currentCategory,
      currentQuestion,
    });
  }

  private getPreviousQuestionAndCleanAnswers(args: {
    currentResult: Result;
    currentCategory: Category;
    currentQuestion: Question;
  }): {
    previousQuestion: Question;
    result: Result;
    previousCategory?: Category;
  } {
    const { currentQuestion, currentCategory, currentResult } = {
      ...args,
    };

    const latestAnswer = currentResult.getLatestAnswer();

    // If no answers are yet given, the wizard needs to be navigate to the home page.
    if (!latestAnswer) {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
      return;
    }

    const skippedQuestions =
      this.questionBranchingService.getPreviousSkippedQuestionAmount(
        currentResult,
        currentQuestion,
        currentCategory,
      );

    const { previousQuestion, goToPreviousCategory } =
      this.questionTraversalService.getPreviousQuestion({
        currentCategory,
        currentQuestion,
        skippedQuestions,
      });

    // Answers have to be removed for the answers-array indexing
    // to keep up with the questions.
    if (skippedQuestions) {
      for (let i = 0; i < skippedQuestions + 1; ++i) {
        currentResult.answers.pop();
      }

      this.testNumberingService.decreaseNumbering(skippedQuestions);
    }

    if (previousQuestion && skippedQuestions === 0) {
      this.removeAnswer({
        currentResult,
        currentCategory,
        currentQuestion,
      });

      this.updateResultService.emitSaveResult(currentResult);
    }

    if (latestAnswer.isPrefilled) {
      this.getPreviousQuestionAndCleanAnswers({
        currentCategory,
        currentQuestion: previousQuestion,
        currentResult,
      });
    } else {
      this.emitPreviousQuestion(
        previousQuestion,
        !goToPreviousCategory && Boolean(currentQuestion),
      );
    }

    if (goToPreviousCategory) {
      this.goToPreviousCategory(skippedQuestions);
      return;
    }
  }

  public updateResult(answer: Answer, result: Result, isPrefilled = false) {
    result.answers.push({
      answer: answer,
      date: new Date(),
      isPrefilled,
    });

    if (answer.co2e) {
      result.co2e += answer.co2e;

      result.categoryCO2e.map(category => {
        if (this.getCurrentCategory()._id === category.category._id) {
          category.co2e += answer.co2e;
        }
      }, this);
    }
  }

  private removeAnswer(args: {
    currentResult: Result;
    currentCategory: Category;
    currentQuestion: Question;
  }): Result {
    const { currentResult, currentCategory } = { ...args };

    const lastAnswer = currentResult.answers.pop();

    const categoryAnswerIds = currentCategory.questions.reduce(
      (prev, curr) => [...prev, ...curr.answers.map(a => a._id)],
      [],
    );

    const lastAnswerIsInCurrentCategory = categoryAnswerIds.some(
      id => id === lastAnswer.answer._id,
    );

    if (lastAnswerIsInCurrentCategory) {
      currentResult.co2e -= lastAnswer.answer.co2e;
      currentResult.categoryCO2e.map(category => {
        if (currentCategory._id === category.category._id) {
          category.co2e -= lastAnswer.answer.co2e;
        }
      }, this);
    }

    if (lastAnswer && lastAnswer.answer === null) {
      return this.removeAnswer(args);
    }

    return currentResult;
  }
}
