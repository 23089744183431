import { Injectable } from '@angular/core';
import { BuildingType } from 'client/app/models/building-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { Municipality } from '../municipality-to-region/municiipality-to-region-map';

export enum ParameterType {
  Municipality = 'municipality',
  LivingArea = 'livingArea',
  BuildingType = 'buildingType',
  ConstructionYear = 'constructionYear',
}

export type ApartmentParameter = {
  parameter: ParameterType;
  value: string;
};

type SupportParameters =
  | 'municipality'
  | 'livingArea'
  | 'buildingType'
  | 'constructionYear';

@Injectable({
  providedIn: 'root',
})
export class ApartmentParametersService {
  private mapping: Record<SupportParameters, ParameterType> = {
    municipality: ParameterType.Municipality,
    livingArea: ParameterType.LivingArea,
    buildingType: ParameterType.BuildingType,
    constructionYear: ParameterType.ConstructionYear,
  };

  private apartmentParametersStream = new BehaviorSubject<ApartmentParameter[]>(
    [],
  );

  public setParameter(args: { key: string; value: string }) {
    const { key, value } = { ...args };
    const mappedParameter = this.mapping[key];

    // skip unknown params
    if (!mappedParameter) {
      return;
    }

    // Don't include parameter if it doesn't pass validation
    if (!this.validateParameter(mappedParameter, value)) {
      return;
    }

    const updatedMap = [
      ...this.apartmentParametersStream.value,
      { parameter: mappedParameter, value },
    ];
    this.apartmentParametersStream.next(updatedMap);
  }

  public getParametersStream(): Observable<ApartmentParameter[]> {
    return this.apartmentParametersStream.asObservable();
  }

  private validateParameter(parameter: ParameterType, value: any): boolean {
    // Validate building year as numeric
    if (
      (parameter === ParameterType.ConstructionYear ||
        parameter === ParameterType.LivingArea) &&
      !(value as number)
    ) {
      return false;
    }

    // Validate municipality as enum member
    if (parameter === ParameterType.Municipality && !(value in Municipality)) {
      return false;
    }

    // Validate building type as enum member
    if (parameter === ParameterType.BuildingType && !(value in BuildingType)) {
      return false;
    }

    return true;
  }
}
