import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoryComponent } from './category/category.component';
import { ChartComponent } from './chart/chart.component';
import { FilterPipe } from './pipes/filter.pipe';
import { RoundToPipe } from './pipes/round-up.pipe';
import { SortByCategoryPipe } from './pipes/sort-by-category.pipe';
import { SortByIndexPipe } from './pipes/sort-by-index.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { QuestionComponent } from './question/question.component';
import { ResultComponent } from './result/result.component';
import { CategoryService } from './services/category.service';
import { AnswerPrefillService } from './services/answer-prefill/answer-prefill.service';
import { ResultService } from './services/result.service';
import { TestService } from './services/test.service';
import { TestComponent } from './test/test.component';
import { TipComponent } from './tip/tip.component';
import { LumoTipsComponent } from './lumo-tips/lumo-tips.component';
import { QuestionTraversalService } from './services/question-traversal-service';
import { Co2CalculationService } from './services/co2-calculation.service';
import { QuestionBranchingService } from './services/question-branching.service';
import { MunicipalityToRegionService } from './services/municipality-to-region.service';
import { TestNumberingService } from './services/singleton/test-numbering.service';
import { GtmTokenFactory } from './GtmTokenFactory';
import { IconLargeEffectComponent } from './tip/svg-templates/icon-large-effect.component';
import { IconSmallEffectComponent } from './tip/svg-templates/icon-small-effect.component';
import { IconMediumEffectComponent } from './tip/svg-templates/icon-medium-effect.component';
import { PreserveParamsRedirectGuard } from './guards/preserve-params-redirect.guard';
import { EmbedContextService } from './services/embed-context.service';
import { FooterComponent } from './footer/footer.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    ResultComponent,
    QuestionComponent,
    CategoryComponent,
    TestComponent,
    ChartComponent,
    RoundToPipe,
    FilterPipe,
    TipComponent,
    SortPipe,
    SortByIndexPipe,
    SortByCategoryPipe,
    ThousandSeparatorPipe,
    LumoTipsComponent,
    IconSmallEffectComponent,
    IconMediumEffectComponent,
    IconLargeEffectComponent,
    FooterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'lifestyletest' }),
    BrowserTransferStateModule,
    FormsModule,
    HttpClientModule,
    TransferHttpCacheModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AppComponent,
    ResultComponent,
    QuestionComponent,
    CategoryComponent,
    TestComponent,
  ],
  providers: [
    CategoryService,
    ResultService,
    TestService,
    AnswerPrefillService,
    QuestionTraversalService,
    Co2CalculationService,
    QuestionBranchingService,
    MunicipalityToRegionService,
    PreserveParamsRedirectGuard,
    {
      provide: 'googleTagManagerId',
      useFactory: GtmTokenFactory,
      deps: [EmbedContextService],
    },
    {
      provide: 'googleTagManagerResourcePath',
      useValue: 'https://gtm.lumo.fi/gtm.js',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
