import { Injectable } from '@angular/core';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { Result } from '../interfaces/result';
import { UpdateResultService } from './singleton/update-result.service';

@Injectable()
export class QuestionTraversalService {
  public constructor(public updateResultService: UpdateResultService) {}

  public getNextQuestion(args: {
    currentCategory: Category;
    currentQuestion: Question;
    skippedQuestions: number;
  }): Question {
    const { currentCategory, currentQuestion, skippedQuestions } = args;

    const indexOfCurrentQuestion = this.getQuestionIndex({
      category: currentCategory,
      question: currentQuestion,
    });

    const nextQuestionIndex = indexOfCurrentQuestion + 1 + skippedQuestions;

    return this.getQuestionFromCategory({
      questionCategory: currentCategory,
      questionIndex: nextQuestionIndex,
    });
  }

  public getPreviousQuestion(args: {
    currentCategory: Category;
    currentQuestion: Question;
    skippedQuestions: number;
  }): { previousQuestion: Question; goToPreviousCategory: boolean } {
    const { currentCategory, currentQuestion, skippedQuestions } = { ...args };

    // Index of the question in category
    const indexOfCurrentQuestion =
      currentCategory.questions.indexOf(currentQuestion);

    // Index 0: First question in a category -> previous category
    // Index -1: Category overview state -> keep category, previous question is the last one
    if (indexOfCurrentQuestion === 0) {
      return {
        previousQuestion:
          currentCategory.questions[
            currentCategory.questions.length - 1 - skippedQuestions
          ],
        goToPreviousCategory: true,
      };
    } else if (indexOfCurrentQuestion === -1) {
      return {
        previousQuestion:
          currentCategory.questions[
            currentCategory.questions.length - 1 - skippedQuestions
          ],
        goToPreviousCategory: false,
      };
    }

    const previousQuestion = this.getQuestionFromCategory({
      questionCategory: currentCategory,
      questionIndex: indexOfCurrentQuestion - 1 - skippedQuestions,
    });

    return {
      previousQuestion,
      goToPreviousCategory: false,
    };
  }

  private getQuestionIndex(args: {
    category: Category;
    question: Question;
  }): number {
    return args.category.questions.findIndex(q => q._id === args.question._id);
  }

  private getQuestionFromCategory = (args: {
    questionCategory: Category;
    questionIndex: number;
  }): Question | undefined => {
    const { questionCategory, questionIndex } = { ...args };

    const totalNumberOfQuestions = questionCategory.questions.length;

    if (questionIndex > totalNumberOfQuestions - 1) {
      return null;
    }

    return questionCategory.questions[questionIndex] as Question;
  };
}
