import { Injectable } from '@angular/core';
import { ResidencyLocationAnswerIndex } from '../models/answer-indices';
import { municipalityToRegionMap } from './municipality-to-region/municiipality-to-region-map';

@Injectable()
export class MunicipalityToRegionService {
  public mapMunicipalityToRegionAnswerIndex(
    municipality: string,
  ): ResidencyLocationAnswerIndex {
    const result = municipalityToRegionMap.find(i =>
      i.municipalityNames.some(n => n === municipality),
    );

    if (!result) {
      throw new Error(
        `Could not find region matching municipality ${municipality}`,
      );
    }

    return result.answerIndex;
  }
}
