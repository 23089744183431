import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundToNearest100',
})
export class RoundToPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round(value / 100) * 100;
  }
}
