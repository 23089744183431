import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Answer } from '../interfaces/answer';
import { Category } from '../interfaces/category';
import { Question } from '../interfaces/question';
import { QuestionIndex } from '../models/question-indices';
import { MunicipalityToRegionService } from '../services/municipality-to-region.service';
import {
  MunicipalitiesToRegion,
  Municipality,
  municipalityToRegionMap,
} from '../services/municipality-to-region/municiipality-to-region-map';
import { TestNumberingService } from '../services/singleton/test-numbering.service';
import { TestService } from '../services/test.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: [
    trigger('slide', [
      state(
        'enter',
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        }),
      ),
      state(
        'in',
        style({
          transform: 'none',
          opacity: 1,
        }),
      ),
      state(
        'leave',
        style({
          transform: 'translateX(-100%)',
          opacity: 0,
        }),
      ),
      transition('enter => in', animate('250ms ease-out')),
      transition('in => leave', animate('250ms ease-in')),
      transition('leave => in', animate('250ms ease-out')),
      transition('in => enter', animate('250ms ease-in')),
    ]),
  ],
})
export class QuestionComponent implements OnInit, OnDestroy {
  public question: Question = new Question({});
  public category: Category;
  public animationState: string = 'enter';
  public answerClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  public municipalityAnswers = municipalityToRegionMap
    .reduce(
      (prev: Municipality[], curr: MunicipalitiesToRegion) => [
        ...prev,
        ...curr.municipalityNames.map(n => n.toString()),
      ],
      [],
    )
    .sort((a, b) => a.localeCompare(b));
  public isMunicipalityQuestion = new BehaviorSubject<boolean>(false);

  private touched: boolean = false;
  private subscription = new Subscription();

  public get currentQuestionNumber$(): Observable<number> {
    return this.testNumberingService.currentQuestionNumber$;
  }

  public get totalQuestionsAmount$(): Observable<number> {
    return this.testNumberingService.totalQuestionsAmount$;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public testService: TestService,
    public translate: TranslateService,
    public municipalityRegionService: MunicipalityToRegionService,
    public testNumberingService: TestNumberingService,
  ) {}

  public ngOnInit() {
    this.subscription.add(
      combineLatest(this.testService.currentQuestion, this.answerClicked)
        .pipe(filter(([_, clickState]) => !clickState))
        .subscribe(([question]) => {
          const isMunicipalityQuestion =
            question?.index === QuestionIndex.ResidencyLocationQuestion;
          this.isMunicipalityQuestion.next(isMunicipalityQuestion);
        }),
    );

    this.subscription.add(
      this.testService.currentQuestion.subscribe((value: Question) => {
        if (this.question && value && this.question.index > value.index) {
          this.animationState = 'enter';
          setTimeout(() => {
            this.animationState = 'leave';
            setTimeout(() => {
              this.question = value;
              this.animationState = 'in';
            }, 125);
          }, 500);
        } else if (this.question && this.question._id === undefined) {
          // if question is first one in category, show question right away
          this.question = value;
          setTimeout(() => {
            this.answerClicked.next(false);
            this.animationState = 'in';
          }, 0);
        } else {
          setTimeout(() => {
            this.animationState = 'enter';
            this.question = value;
            setTimeout(() => {
              this.answerClicked.next(false);
              this.animationState = 'in';
            }, 125);
          }, 500);
        }

        // if you don't want to focus the title for first question, you have to handle the initial focus on the page for screen readers
        // if (this.touched) {
        setTimeout(() => {
          this.focusTitle();
        }, 800);
        // }
      }),
    );

    this.subscription.add(
      this.testService.currentCategory.subscribe((value: Category) => {
        this.category = value;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public focusTitle() {
    if (isPlatformBrowser(this.platformId)) {
      const focusEl = document.getElementById('question-title');
      if (focusEl) {
        focusEl.focus();
      }
    }
  }

  public saveAnswer(answer: Answer) {
    this.touched = true;
    this.answerClicked.next(true);
    this.testService.saveAnswer(answer);

    setTimeout(() => {
      this.animationState = 'leave';
    }, 0);
  }

  public saveMunicipalityAnswer(municipality: Municipality) {
    const answerIndex =
      this.municipalityRegionService.mapMunicipalityToRegionAnswerIndex(
        municipality,
      );
    const answer = this.question.answers.find(a => a.index === answerIndex);
    this.saveAnswer(answer);
  }
}
